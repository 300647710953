<template>
  <div class="register-form">
    <BaseInput
      ref="username"
      v-model="formRegister.username"
      :label="FORM_AUTH.LABEL_USERNAME"
      placeholder=" "
      required
      :min-length="$config.MIN_LENGTH_USER_NAME"
      :max-length="$config.MAX_LENGTH_USER_NAME"
      :limit-input="$config.MAX_LENGTH_USER_NAME"
      special-character
      :error-special-character="FORM_AUTH.VALIDATE_USERNAME"
      :is-show-error-custom="isNameExists"
      @update:model-value="isNameExists = false"
      @keyup.enter="password?.focus()"
    >
      <template v-if="isNameExists" #error>
        {{ FORM_AUTH.TEXT_EXIST }}
      </template>
    </BaseInput>
    <BaseInput
      ref="password"
      v-model.trim="formRegister.password"
      :label="FORM_AUTH.LABEL_PASSWORD"
      type="password"
      placeholder=" "
      :allow-validate-password="true"
      required
      :min-length="$config.MIN_LENGTH_PASSWORD"
      :max-length="$config.MAX_LENGTH_PASSWORD"
      :limit-input="$config.MAX_LENGTH_PASSWORD"
      @keyup.enter="phone?.focus()"
    />
    <BaseInput
      ref="phone"
      v-model="formRegister.phone"
      :label="FORM_AUTH.LABEL_PHONE_NUMBER"
      type="tel"
      placeholder=" "
      required
      :max-length="10"
      :min-length="10"
      :limit-input="10"
      phone-character
      :error-phone-character="FORM_AUTH.VALIDATE_PHONE"
      start-zero
    />
    <div class="check-box">
      <BaseCheckbox id="agree" v-model="isAgree" :label-content="FORM_AUTH.LABEL_CHECKBOX" />
    </div>
    <BaseButton
      :disabled="!formRegister.password || !formRegister.username || !formRegister.phone || !isAgree"
      class="base-button--btn-primary"
      :is-loading="isLoading"
      @click="submit"
    >
      {{ FORM_AUTH.REGISTER }}
    </BaseButton>
  </div>
</template>
<script setup lang="ts">
import { useRegister } from '~/composables/user/useRegister.js'
import { FORM_AUTH } from '~/constants/form/auth'
import BaseInput from '~/components/common/base-input'
import BaseButton from '~/components/common/base-button'
import BaseCheckbox from '~/components/common/base-checkbox'
const { $config } = useNuxtApp()
const props = withDefaults(
  defineProps<{
    tabActive?: string
    isShowModal?: boolean
  }>(),
  {
    tabActive: '',
    isShowModal: false
  }
)
const { isAgree, isNameExists, username, password, phone, formRegister, isLoading, submit } = useRegister()

const route = useRoute()

watch(route, () => {
  username?.value?.resetValidate()
  password?.value?.resetValidate()
  phone?.value?.resetValidate()
})

watch(username, () => {
  isNameExists.value = false
})

const handleKeyPress = (event) => {
  if (event.key === 'Enter' && isAgree.value) {
    submit()
  }
}

onMounted(() => {
  window.addEventListener('keyup', handleKeyPress)
})

onUnmounted(() => {
  window.removeEventListener('keyup', handleKeyPress)
})
</script>
<style lang="scss" scoped src="assets/scss/components/common/form/register.scss" />
